import { atom, selector } from "recoil";
import { recoilPersist } from "recoil-persist";
import { getstrquery } from "../utils/query.js";
const { persistAtom } = recoilPersist();

export const filterSubscriptionState = atom({
  key: "filterSubscriptionState",
  default: {
    accountName: "",
    tradingSoftware: "",
    page: 1,
    limit: 20,
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterSubscriptionSelector = selector({
  key: "filterSubscriptionSelector",
  get({ get }) {
    const { skip, accountName, tradingSoftware, page, limit } = get(
      filterSubscriptionState
    );
    let query = ``;
    if (accountName) {
      query += `type=accounts&search=${accountName}`;
    }
    if (tradingSoftware) {
      query += `type=tradingSoftware&search=${tradingSoftware}`;
    }
    // query += `page=${page}&limit=${limit}`;
    return query;
  },
});

export const filterBookmapLicenseState = atom({
  key: "filterBookmapLicenseState",
  default: {
    accountName: "",
    email: "",
    isActif: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterBookmapLicenseSelector = selector({
  key: "filterBookmapLicenseSelector",
  get({ get }) {
    const { accountName, email, isActif } = get(filterBookmapLicenseState);
    let query = ``;
    if (accountName) {
      query += `${accountName}`;
    }
    if (email) {
      query += `${email}`;
    }
    if (isActif) {
      query += `type=status&search=${isActif}`;
    }

    return query;
  },
});

export const filterBlacklistState = atom({
  key: "filterBlacklistState",
  default: {
    fName: "",
    email: "",
    search: "",
    cd: "",
  },
  effects_UNSTABLE: [persistAtom],
});
export const filterCouponlistState = atom({
  key: "filterCouponlistState",
  default: {
    coupon: "",
    email: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterCouponslistSelector = selector({
  key: "filterCouponslistSelector",
  get({ get }) {
    const { coupon, email } = get(filterCouponlistState);
    let query = ``;
    if (coupon) {
      query += `${coupon}`;
    }
    if (email) {
      query += `${email}`;
    }
    return query;
  },
});

export const filterPaymentlistState = atom({
  key: "filterPaymentlistState",
  default: {
    action_type: "",
    transaction_id: "",
    condition: "",
    start_date: "",
    result_order: "",
    end: "",
    first_name: "",
    last_name: "",
    email: "",
    security_key: "",
    cc_type: "",
    page_number: 1,
    result_limit: 10,
  },
  effects_UNSTABLE: [persistAtom],
});
export const filterPaymentlistSelector = selector({
  key: "filterPaymentlistSelector",
  get({ get }) {
    const {
      result_order,
      action_type,
      transaction_id,
      condition,
      start_date,
      first_name,
      last_name,
      end,
      email,
      cc_type,
      security_key,
    } = get(filterPaymentlistState);
    let query = ``;
    if (result_order) {
      query += `result_order=${result_order}`;
    }
    if (action_type) {
      query += `action_type=${action_type}`;
    }
    if (transaction_id) {
      query += `transaction_id=${transaction_id}`;
    }
    if (condition) {
      query += `condition=${condition}`;
    }
    if (start_date) {
      query += `start_date=${start_date}`;
    }
    if (first_name) {
      query += `first_name=${first_name}`;
    }
    if (last_name) {
      query += `last_name=${last_name}`;
    }
    if (end) {
      query += `end_date=${end}`;
    }
    if (email) {
      query += `email=${email}`;
    }
    if (cc_type) {
      query += `cc_type=${cc_type}`;
    }
    if (security_key) {
      query += `security_key=${security_key}`;
    }

    return query;
  },
});

export const filterBlacklistSelector = selector({
  key: "filterBlacklistSelector",
  get({ get }) {
    const { fName, email, search, cd } = get(filterBlacklistState);
    let query = ``;
    if (fName) {
      query += `${fName}`;
    }
    if (email) {
      query += `${email}`;
    }
    if (search) {
      query += `search=${search}`;
      // console.log(query);
    }
    if (cd) {
      query += `&cd=${cd}`;
      // console.log(query);
    }

    return query;
  },
});

export const filterPayoutState = atom({
  key: "filterPayoutState",

  default: {
    fullName: "",
    email: "",
    country: "",
    address: "",
    paymentMethod: "",
    cin_passport: "",
    accountType: "",
    ticketNbr: "",
    deleted: false,
    paymentStatus: "",
    page: 1,
  },

  effects_UNSTABLE: [persistAtom],
});

export const filterPayoutSelector = selector({
  key: "filterPayoutSelector",
  get({ get }) {
    const {
      fullName,
      email,
      country,
      address,
      paymentMethod,
      cin_passport,
      accountType,
      ticketNbr,
      deleted,
      paymentStatus,
      page,
      payoutID,
      createdAt,
      updatedAt,
    } = get(filterPayoutState);
    let query = ``;
    // console.log("is deleted = ", deleted);
    console.log("query", get(filterPayoutState));
    if (payoutID) {
      query += `_id=${payoutID}`;
    }
    if (fullName) {
      query += `fullName=${fullName}`;
    }
    if (email) {
      query += `email=${email}`;
    }
    if (country) {
      query += `country=${country}`;
    }
    if (address) {
      query += `address=${address}`;
    }
    if (paymentMethod) {
      query += `paymentMethod=${paymentMethod}`;
    }
    if (cin_passport) {
      query += `cin_passport=${cin_passport}`;
    }
    if (accountType) {
      query += `accountType=${accountType}`;
    }
    if (ticketNbr) {
      query += `ticketNbr=${ticketNbr}`;
    }
    if (paymentStatus) {
      query += `paymentStatus=${paymentStatus}`;
    }

    if (page) {
      query += `&page=${page}`;
    }
    if (createdAt) {
      query += `&createdAt=${createdAt}`;
    }
    if (updatedAt) {
      query += `&updatedAt=${updatedAt}`;
    }
    if (deleted && deleted !== undefined) query += `&isDeleted=${deleted}`;
    return query;
  },
});
export const filterJournalytixState = atom({
  key: "filterJournalytixState",

  default: {
    Username: "",
    Email: "",
    page: 1,
  },

  effects_UNSTABLE: [persistAtom],
});

export const filterJournalytixSelector = selector({
  key: "filterJournalytixSelector",
  get({ get }) {
    const { Email, Username, page } = get(filterJournalytixState);
    console.log("tes ", get(filterJournalytixState));
    let query = ``;
    if (Username) {
      query += `Username=${Username}`;
    }
    if (Email) {
      query += `Email=${Email}`;
    }

    if (page) {
      query += `&page=${page}`;
    }

    return query;
  },
});
export const filterPayoutHistoryState = atom({
  key: "filterPayoutHistoryState",

  default: {
    action: "",
    email: "",
    page: 1,
  },

  effects_UNSTABLE: [persistAtom],
});

export const filterPayoutHistorySelector = selector({
  key: "filterPayoutHistorySelector",
  get({ get }) {
    const { email, action, page } = get(filterPayoutHistoryState);
    console.log("tes ", get(filterPayoutHistoryState));
    let query = ``;
    if (action) {
      query += `action=${action}`;
    }
    if (email) {
      query += `&email=${email}`;
    }

    // if (page) {
    //   query += `&page=${page}`;
    // }

    return query;
  },
});

//
export const filterFundedlistState = atom({
  key: "filterFundedlistState",
  default: {
    //accountId: undefined,
    //userId: undefined,
    //payed: undefined,
    //accountCreated: undefined,
    //creation_status: undefined,
    //email: undefined,
    //is_signin: undefined,
    //kyc_status: undefined,
    //invoice_status: undefined,
    //provider: undefined,
    //accountType: undefined,
    //is_uae: undefined,

    //accountId: "",
    //userId: "",
    //payed: "",
    //accountCreated: "",
    //creation_status: [],
    //email: "",
    //is_signin: "",
    //kyc_status: "",
    //invoice_status: [],
    //provider: "",
    //accountType: [],
    //is_uae: "",

    page: 1,
    limit: 10,
  },
  //  effects_UNSTABLE: [persistAtom],
});

export const filterFundedlistSelector = selector({
  key: "filterFundedlistSelector",
  get({ get }) {
    return get(filterFundedlistState);
    //const {
    //  accountId,
    //  userId,
    //  payed,
    //  accountCreated,
    //  creation_status,
    //  email,
    //  is_signin,
    //  kyc_status,
    //  invoice_status,
    //  provider,
    //  is_uae,
    //  accountType,
    //} = get(filterFundedlistState);

    //let query = ``;
    //if (provider) {
    //  query += `&provider=${provider}`;
    //}
    //if (is_uae) {
    //  query += `&is_uae=${is_uae}`;
    //}
    //if (accountType) {
    //  for (const acc of accountType) {
    //    query += `&accountType[]=${acc}`;
    //  }
    //}
    //if (accountId) {
    //  query += `&accountId=${accountId}`;
    //}
    //if (userId) {
    //  query += `&userId=${userId}`;
    //}
    //if (payed) {
    //  query += `&payed=${payed}`;
    //}
    //if (accountCreated) {
    //  query += `&accountCreated=${accountCreated}`;
    //}
    //if (creation_status) {
    //  for (const cst of creation_status) {
    //    query += `&creation_status[]=${cst}`;
    //  }
    //}
    //if (email) {
    //  query += `&email=${email}`;
    //}
    //if (is_signin) {
    //  query += `&is_signin=${is_signin}`;
    //}
    //if (kyc_status) {
    //  for (const kyc of kyc_status) {
    //    query += `&kyc_status[]=${kyc}`;
    //  }
    //}
    //if (invoice_status) {
    //  for (const ist of invoice_status) {
    //    query += `&invoice_status[]=${ist}`;
    //  }
    //}

    //return query;
  },
});

// filterMultipleAccountState
export const filterMultipleAccountState = atom({
  key: "filterMultipleAccountState",
  default: {
    public_id: "",
    invoice_id: "",
    amember_user_id: "",
    target_account: "",
    provider: "",
    rithmic_Id: "",
    resolved: undefined,
    cqg_Id: "",
    page: 1,
    limit: 10,
  },
  //  effects_UNSTABLE: [persistAtom],
});

export const filterMultipleAccountSelector = selector({
  key: "filterMultipleAccountSelector",
  get({ get }) {
    return get(filterMultipleAccountState);

    //const {
    //  public_id,
    //  invoice_id,
    //  amember_user_id,
    //  target_account,
    //  provider,
    //  rithmic_Id,
    //  resolved,
    //  cqg_Id,
    //} = get(filterMultipleAccountState);
    //let query = ``;
    //if (public_id) {
    //  query += `&public_id=${public_id}`;
    //}
    //if (invoice_id) {
    //  query += `&invoice_id=${invoice_id}`;
    //}
    //if (amember_user_id) {
    //  query += `&amember_user_id=${amember_user_id}`;
    //}
    //if (target_account) {
    //  query += `&target_account=${target_account}`;
    //}
    //if (provider) {
    //  query += `&provider=${provider}`;
    //}
    //if (rithmic_Id) {
    //  query += `&rithmic_Id=${rithmic_Id}`;
    //}
    //if (cqg_Id) {
    //  query += `&cqg_Id=${cqg_Id}`;
    //}
    //if (resolved || resolved === false) {
    //  query += `&resolved=${resolved}`;
    //}

    //return query;
  },
});

// filterConsitency
export const filterConsistencyListState = atom({
  key: "filterConsistencyListState",
  default: {
    AccountId: "",
    UserId: "",
    provider: "all",
    page: 1,
    limit: 20,
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterConsistencyListcSelector = selector({
  key: "filterConsistencyListcSelector",
  get({ get }) {
    const { AccountId, UserId, page, provider } = get(
      filterConsistencyListState
    );
    let query = ``;
    if (AccountId) {
      query += `&AccountId=${AccountId}`;
    }
    if (UserId) {
      query += `&UserId=${UserId}`;
    }
    if (provider) {
      query += `&provider=${provider}`;
    }
    if (page) {
      query += `&page=${page}`;
    }

    return query;
  },
});

// filterResetAccount
export const filterResetAccountListState = atom({
  key: "filterResetAccountListState",
  default: {
    accountId: "",
    rithmicId: "",
    email: "",
    page: 1,
    limit: 20,
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterResetAccountListSelector = selector({
  key: "filterResetAccountListSelector",
  get({ get }) {
    const { accountId, rithmicId, page, limit, email } = get(
      filterResetAccountListState
    );
    let query = ``;
    if (accountId) {
      query += `&accountId=${accountId}`;
    }
    if (rithmicId) {
      query += `&rithmicId=${rithmicId}`;
    }
    if (email) {
      query += `&email=${email}`;
    }
    if (page) {
      query += `&page=${page}`;
    }
    if (limit) {
      query += `&limit=${limit}`;
    }

    return query;
  },
});

export const filterAddCramAccountstate = atom({
  key: "filterAddCramAccountstate",
  default: {
    accountId: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterAddCramAccountSelector = selector({
  key: "filterAddCramAccountselector",
  get({ get }) {
    const { accountId } = get(filterAddCramAccountstate);
    let query = ``;
    if (accountId) {
      query += `&accountId=${accountId}`;
    }

    return query;
  },
});

export const filterTRCheckerState = atom({
  key: "filterTRCheckerState",
  default: {
    accountName: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterTRCheckerSelector = selector({
  key: "filterTRCheckerselector",
  get({ get }) {
    const { accountName } = get(filterTRCheckerState);
    let query = ``;
    if (accountName) {
      query += `&accountName=${accountName}`;
    }

    return query;
  },
});

export const filterCertifState = atom({
  key: "filterCertifState",
  default: {
    accountName: "",
  },
  effects_UNSTABLE: [persistAtom],
});

export const filterCertifSelector = selector({
  key: "filterCertifselector",
  get({ get }) {
    const { accountName } = get(filterCertifState);
    let query = ``;
    if (accountName) {
      query += `&accountName=${accountName}`;
    }

    return query;
  },
});
