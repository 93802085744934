import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Select,
  Button,
  Heading,
  Badge,
  Collapse,
  useDisclosure,
  useToast,
  Grid,
  GridItem,
  Text,
  Link,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import Pagination1 from "../../utils/pagination"; // Assuming you have a pagination component
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import axios from "axios";

const BooleanBadge = ({ value }) => (
  <Badge colorScheme={value ? "green" : "red"}>{value ? "Yes" : "No"}</Badge>
);

const InfoText = ({ label, value }) => (
  <Box>
    <Text>
      <Text as="span" fontWeight="bold">
        {label}:
      </Text>{" "}
      {value || "N/A"}
    </Text>
  </Box>
);

const SocialLink = ({ label, url }) => (
  <Box>
    <Text fontWeight="bold">{label}:</Text>
    {url ? (
      <Link href={url} color="blue.500" isExternal>
        {url}
      </Link>
    ) : (
      <Text>N/A</Text>
    )}
  </Box>
);

const ExpandableCard = ({ signup, setFetchTrigger }) => {
  const { isOpen, onToggle } = useDisclosure();
  const toast = useToast();

  const handleStatusChange = async (newStatus) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_HOST}/affiliateSignup/approveOrReject/${signup.email}`,
        { status: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Success",
          description: `Signup ${newStatus} successfully.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFetchTrigger((prev) => prev + 1);
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast({
        title: "Error",
        description: "Failed to update status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={6} mb={4} borderWidth={1} borderRadius="md" boxShadow="md">
      {/* Header Section */}
      <Flex justify="space-between" align="center" mb={4}>
        <Heading size="lg">{signup.full_name}</Heading>
        <Flex align="center" gap={3}>
          <Badge
            px={3}
            py={1}
            fontSize="lg"
            colorScheme={
              signup.status === "pending"
                ? "yellow"
                : signup.status === "rejected"
                ? "red"
                : "green"
            }
            rounded="xl">
            {signup.status.toUpperCase()}
          </Badge>
          <Box onClick={onToggle} cursor="pointer">
            {isOpen ? (
              <ChevronUpIcon boxSize={6} />
            ) : (
              <ChevronDownIcon boxSize={6} />
            )}
          </Box>
        </Flex>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
          {/* Personal Information */}
          <GridItem>
            <Heading size="lg" mb={2}>
              Personal Information
            </Heading>
            <InfoText label="Full Name" value={signup.full_name} />
            <InfoText label="Email" value={signup.email} />
            <InfoText label="Phone Number" value={signup.phone_number} />
            <InfoText label="Country" value={signup.country} />
            <InfoText label="User ID" value={signup.user_id} />
          </GridItem>

          {/* Social Media Links */}
          <GridItem>
            <Heading size="lg" mb={2}>
              Social Media Links
            </Heading>
            <SocialLink label="Instagram" url={signup.instagram_url} />
            <SocialLink label="YouTube" url={signup.youtube_url} />
            <SocialLink label="Twitter" url={signup.twitter_url} />
            <SocialLink label="Facebook" url={signup.facebook_url} />
            <SocialLink label="TikTok" url={signup.tiktok_url} />
            <SocialLink label="LinkedIn" url={signup.linkedin_url} />
            <SocialLink label="Telegram" url={signup.telegram_url} />
          </GridItem>

          {/* Other Information */}
          <GridItem>
            <Heading size="lg" mb={2}>
              Other Information
            </Heading>
            <InfoText
              label="Primary Platform"
              value={signup.primary_platform}
            />
            <InfoText label="Followers Count" value={signup.followers_count} />
            <InfoText label="Engagement Rate" value={signup.engagement_rate} />
            <SocialLink label="Website" url={signup.website_url} />
            <InfoText
              label="Monthly Visitors"
              value={signup.monthly_visitors}
            />
            <InfoText
              label="Has Email List"
              value={<BooleanBadge value={signup.has_email_list} />}
            />
            <InfoText
              label="Email Subscribers"
              value={signup.email_subscribers}
            />
            <InfoText
              label="Manages Trading Community"
              value={<BooleanBadge value={signup.manages_trading_community} />}
            />
            <SocialLink label="Community Link" url={signup.community_link} />
            <InfoText
              label="Community Members"
              value={signup.community_members}
            />
          </GridItem>

          {/* Marketing & Affiliate Information */}
          <GridItem>
            <Heading size="lg" mb={2}>
              Marketing & Affiliates
            </Heading>
            <InfoText
              label="Marketing Methods"
              value={signup.marketing_methods?.join(", ")}
            />
            <InfoText
              label="Other Marketing Method"
              value={signup.other_marketing_method}
            />
            <InfoText
              label="Affiliate Experience"
              value={signup.affiliate_experience}
            />
            <InfoText
              label="Is Affiliate For Others"
              value={<BooleanBadge value={signup.is_affiliate_for_others} />}
            />
            <InfoText
              label="Affiliated Platforms"
              value={signup.affiliated_platforms?.join(", ")}
            />
            <InfoText
              label="Promotion Strategy"
              value={signup.promotion_strategy}
            />
          </GridItem>
        </Grid>

        {/* Action Buttons */}
        <Flex mt={6} justify="center" gap={4}>
          <Button
            colorScheme="green"
            onClick={() => handleStatusChange("approved")}>
            Approve
          </Button>
          <Button
            colorScheme="red"
            onClick={() => handleStatusChange("rejected")}>
            Reject
          </Button>
        </Flex>
      </Collapse>
    </Box>
  );
};

const AffiliateSignupList = () => {
  const [status, setStatus] = useState("");
  const [affiliateSignups, setAffiliateSignups] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [email, setEmail] = useState("");
  const [nameContains, setNameContains] = useState("");
  const [country, setCountry] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const fetchAffiliateSignups = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/affiliateSignup`,
          {
            params: {
              limit: 10,
              page,
              status,
              email,
              nameContains,
              country,
              user_id: userId,
            },
          }
        );
        const data = response.data;
        console.log(data);
        setAffiliateSignups(data.docs);
        setTotalPages(data.totalPages);
      } catch (error) {
        toast({
          title: "Error fetching affiliate signups",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchAffiliateSignups();
  }, [page, status, email, nameContains, country, userId, fetchTrigger]);

  return (
    <Box p={4}>
      <Heading mb={4}>Affiliate Signups</Heading>
      <Grid
        templateColumns="repeat(3, 300px)"
        justifyContent={"center"}
        alignItems={"center"}
        gap={4}
        px={16}
        mb={4}>
        <GridItem>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              placeholder="Select Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}>
              <option value="pending">Pending</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Full Name</FormLabel>
            <Input
              placeholder="Full Name"
              value={nameContains}
              onChange={(e) => setNameContains(e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Country</FormLabel>
            <Input
              placeholder="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>User ID</FormLabel>
            <Input
              placeholder="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </FormControl>
        </GridItem>
      </Grid>
      <Box>
        <Grid templateColumns="repeat(3, 1fr)" gap={6}>
          {affiliateSignups.map((signup, index) => (
            <GridItem key={index}>
              <ExpandableCard
                signup={signup}
                fetchTrigger={fetchTrigger}
                setFetchTrigger={setFetchTrigger}
              />
            </GridItem>
          ))}
        </Grid>
      </Box>
      {affiliateSignups.length === 0 && (
        <Box
          mt={10}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h={"screen"}>
          <Text
            className="text-center text-gray-500 font-bold text-6xl"
            fontSize={"2xl"}
            fontWeight={"bold"}>
            No affiliate signups found
          </Text>
        </Box>
      )}
      <Pagination1
        pagesQuantity={totalPages}
        curPage={page}
        setCurPage={setPage}
      />
    </Box>
  );
};

export default AffiliateSignupList;
