import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  chakra,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Text,
  Tooltip,
  useToast,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Select,
  FormLabel,
  FormControl,
  Switch,
  Grid,
  GridItem,
  Stack,
  StackDivider,
  Badge,
  Table,
  Thead,
  Tbody,
  Tr,
  Spinner,
  Th,
  Td,
} from "@chakra-ui/react";
import { CSVLink } from "react-csv";
import React, { useState, useRef } from "react";
import { colors } from "../../utils/colors";
import { BiChevronDown, BiDetail, BiSearch, BiTrash } from "react-icons/bi";
// import {  } from "react-super-responsive-table";
import "../../../node_modules/react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import useBlacklist from "../../hooks/useQueries/useBlacklist";
import useBlacklistMutation from "../../hooks/useMutations/useBlacklistMutation";
import { useRecoilValue, useRecoilState } from "recoil";
import {
  filterBlacklistSelector,
  filterBlacklistState,
  filterPayoutSelector,
  filterPayoutState,
} from "../../recoil";
import { BiFilter } from "react-icons/bi";
import { AiOutlineEye } from "react-icons/ai";
import usePayoutList from "../../hooks/useQueries/usePayoutlist";
import { useAuth } from "../../utils/auth";
import axios from "axios";
import { useNavigate } from "react-router";
import { countriesList } from "./CreatePayout";
import { useEffect } from "react";
import { client } from "../../utils/axios";
import moment from "moment";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import Pagination1 from "../../utils/pagination";

const headers = [
  { label: "Account ID", key: "accountID" },
  { label: "Requested Amount", key: "requestedAmount" },
  { label: "Withdraw Amount", key: "withdrawAmount" },
  { label: "ID | Passport", key: "cin_passport" },
  { label: "Country", key: "country" },
  { label: "Full Name", key: "fullName" },
  { label: "Email", key: "email" },
  { label: "User ID", key: "userID" },
  { label: "Ticket Number", key: "ticketNbr" },
  { label: "Address", key: "address" },
  { label: "Payment Method", key: "paymentMethod" },
  { label: "Payment ID", key: "paymentId" },
  { label: "Account Balance", key: "accountBalance" },
  { label: "Account Type", key: "accountType" },
  { label: "Payment Status", key: "paymentStatus" },
  { label: "Validation Status", key: "validationStatus" },
  { label: "Communication Status", key: "communicationStatus" },
  { label: "Client Response Status", key: "clientResponseStatus" },
  { label: "Network", key: "network" },
  // { label: "Crypto Currency", key: "cryptoCurrency" },
  { label: "Active Trading Days", key: "nbrActiveTradingDays" },
  { label: "Account Creation Date", key: "AccountCreationDate" },
  {
    label: "Withdraw Percentage Permitted",
    key: "withdrawPercentagePermitted",
  },
  { label: "Deleted At", key: "deletedAt" },
  { label: "Created At", key: "createdAt" },
];
const crypto = ["BNB", "USDT", "BUSD", "BTC", "ETH", "XVG", "USDC", "TUSD"];

// const CustomTable = chakra(Table);
// const CustomThead = chakra(Thead);
// const CustomTbody = chakra(Tbody);
// const CustomTr = chakra(Tr);
// const CustomTh = chakra(Th);
// const CustomTd = chakra(Td);
const paymentIdType = (paymentMethod) => {
  if (paymentMethod === "Bank Transfer") return "IBAN";
  else if (paymentMethod === "crypto") return "Wallet Address";
  else return "DEEL Account";
};

function CustomToolTip({ paymentId, paymentMethod }) {
  return (
    <div>
      <Tooltip label={paymentIdType(paymentMethod)}>{paymentId}</Tooltip>
    </div>
  );
}

function ListPayout() {
  const [searchBy, setSearchBy] = useState("fullName");
  const [filterBy, setFilterBy] = useState("All");
  const [search, setSearch] = useState("");

  const query = useRecoilValue(filterPayoutSelector);
  const [filterBlacklist, setFilterBlacklist] =
    useRecoilState(filterPayoutState);
  const [csvReport, setSCVReport] = useState({ data: [] });

  const { payoutlist, isLoading, refetchPayoutList } = usePayoutList(query);

  const auth = useAuth()?.user;
  // const roles = auth?.roles;
  const sessionContext = useSessionContext()?.accessTokenPayload;
  const roles =
    sessionContext && sessionContext["st-role"]?.v
      ? sessionContext["st-role"]?.v
      : [];

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [statusUpdate, setStatusUpdate] = useState({
    status: "",
    payout: {},
    reason: "",
  });
  const clickRef = useRef();
  const [exportCheck, setExportCheck] = useState(false);

  useEffect(() => {
    return () => {
      setSearch("");
      setFilterBlacklist({
        ...filterBlacklist,
        fullName: "",
        email: "",
        country: "",
        address: "",
        paymentMethod: "",
        cin_passport: "",
        accountType: "",
        ticketNbr: "",
        [searchBy]: search,
        page: 1,
      });
      refetchPayoutList();
    };
  }, []);
  useEffect(() => {
    if (exportCheck)
      if (csvReport?.data.length > 0) {
        clickRef.current.link.click();
        setExportCheck(false);
      }
  }, [csvReport]);

  const exportReport = async () => {
    console.log("exportReport");
    setExportCheck(true);
    await client
      .get(`${process.env.REACT_APP_API_HOST}/payout/all?${query}`, {
        // headers: {
        //   "auth-token": auth.accessToken,
        // },
      })
      .then((res) => {
        setSCVReport({
          data: res?.data?.docs.map((e) => e),
          headers: headers,
          filename: `Payout - ${new Date()}.csv`,
        });
        console.log("exportReport", res);
        // csvReport = {
        //   data: res?.data?.map((e) => e),
        //   headers: headers,
        //   filename: `Bookmap Licenses - ${new Date()}.csv`,
        // if (res.data.length > 0)
        // if (csvReport?.data.length > 0) clickRef.current.link.click();
        // };
      });
  };
  const checkColor = (status) => {
    console.log("test = ", status);
    if (status === "done") return "green.400";
    else if (status === "pending") return "yellow.400";
    else if (status === "WaitingForCustomer") return "orange.400";
    else return "red.400";
  };

  const inputRef = useRef(null);
  // if (
  //   inputRef &&
  //   inputRef?.current?.value !== search &&
  //   inputRef?.current?.value === ""
  // ) {
  //   console.log(" we here");
  //   setSearch("");
  // }

  const [networkL, setNetworkL] = useState("");
  const [cryptC, setCryptoC] = useState("");

  const navigate = useNavigate();

  const toast = useToast();

  const [deletePayout, setDeletePayout] = useState({});
  const [editPayout, setEditPayout] = useState({});
  const [detailsPayot, setDetailsPayout] = useState({});

  return (
    <div>
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 70px)"
        color="gray.50"
        overflow="auto">
        <Flex align="center" justify="space-between" pb="40px">
          <Box w="20%">
            <Heading size="lg">Payout list</Heading>
          </Box>
          <Flex align="center" justify={`center`} w="100%">
            <Box w="20%">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<BiFilter size="20px" />}
                  borderRadius={50}
                  fontSize="12px"
                  // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                  boxShadow="lg"
                  className="app_btn"
                  color="#ffff"
                  // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                >
                  Search by:{" "}
                  {searchBy == "cin_passport" ? "ID | Passport" : searchBy}
                </MenuButton>
                <MenuList color={colors.ocean} fontSize="14px">
                  <MenuItem onClick={() => setSearchBy("payoutID")}>
                    PayoutID
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("fullName")}>
                    Full Name
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("email")}>
                    Email
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("country")}>
                    Country
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("accountType")}>
                    AccountType
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("address")}>
                    Address
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("paymentMethod")}>
                    Payment Method
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("cin_passport")}>
                    ID | Passport
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("ticketNbr")}>
                    Ticket Number
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("createdAt")}>
                    Created At
                  </MenuItem>
                  <MenuItem onClick={() => setSearchBy("updatedAt")}>
                    Updated At
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>

            <Box w="70%">
              <Input
                type="text"
                placeholder={`Search by ${
                  searchBy == "cin_passport" ? "ID | Passport" : searchBy
                }...`}
                borderColor={colors.lightBlue}
                color={colors.lightBlue}
                _focus={{ borderColor: colors.lightBlue }}
                bgColor="gray.100"
                borderRadius={20}
                boxShadow="lg"
                _placeholder={{
                  opacity: 0.7,
                  color: colors.ocean,
                  fontSize: 14,
                  fontStyle: "italic",
                }}
                onChange={(e) => setSearch(e.target.value)}
                ref={inputRef}
              />
            </Box>

            <Box w="10%" ml="5px">
              <Button
                borderRadius={50}
                fontSize="12px"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                className="app_btn"
                boxShadow="lg"
                color="#ffff"
                // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                isDisabled={!!search ? false : true}
                onClick={() => {
                  setFilterBlacklist({
                    ...filterBlacklist,
                    id: "",
                    fullName: "",
                    email: "",
                    country: "",
                    address: "",
                    paymentMethod: "",
                    cin_passport: "",
                    accountType: "",
                    ticketNbr: "",
                    createdAt: "",
                    updatedAt: "",
                    [searchBy]: search,
                    page: 1,
                  });
                }}>
                <BiSearch size="15px" />
              </Button>
            </Box>

            <Button
              rightIcon={<AiOutlineEye size="20px" />}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              boxShadow="lg"
              color="#ffff"
              // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
              className="app_btn"
              onClick={() => {
                setFilterBlacklist({
                  fullName: "",
                  email: "",
                  country: "",
                  address: "",
                  paymentMethod: "",
                  cin_passport: "",
                  accountType: "",
                  ticketNbr: "",
                  createdAt: "",
                  updatedAt: "",
                  deleted: false,
                  page: 1,
                });
              }}>
              View all
            </Button>
          </Flex>
          <Flex>
            <Flex align="center" justify={`start`} w="100%">
              <Box w="20%">
                <Menu>
                  <MenuButton
                    as={Button}
                    rightIcon={<BiFilter size="20px" />}
                    borderRadius={50}
                    fontSize="12px"
                    // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                    boxShadow="lg"
                    color="#ffff"
                    // _hover={{ bgGradient: `linear(to-r, #008ab4, #46ced8)` }}
                    className="app_btn">
                    Filter by: {filterBy}
                  </MenuButton>
                  <MenuList color={colors.ocean} fontSize="14px">
                    <MenuItem
                      onClick={() => {
                        setFilterBlacklist({
                          ...filterBlacklist,
                          deleted: false,
                          paymentStatus: undefined,
                        });
                        setFilterBy("All");
                      }}>
                      All
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setFilterBlacklist({
                          ...filterBlacklist,
                          deleted: true,
                        });
                        setFilterBy("Deleted");
                      }}>
                      Deleted
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setFilterBlacklist({
                          ...filterBlacklist,
                          deleted: false,
                          paymentStatus: "rejected",
                        });
                        setFilterBy("Rejected");
                      }}>
                      Rejected
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
          </Flex>
          {/* <FormLabel htmlFor="deleted-payout" mb="0" ml={10}>
                Show deleted payouts
              </FormLabel> */}
          {/* <Switch
                id="deleted-payout"
                onChange={() => {
                  setFilterBlacklist({
                    ...filterBlacklist,
                    deleted: !filterBlacklist.deleted,
                  });
                }}
              /> */}
          <Box pt="0px">
            <Button
              onClick={() => exportReport()}
              borderRadius={50}
              fontSize="12px"
              // bgGradient={`linear(to-b, green.400, green.500)`}
              boxShadow="lg"
              color="#ffff"
              className="app_btn"
              // _hover={{ bgGradient: `linear(to-r, green.400, green.500)` }}
              w="100%">
              Export All
            </Button>
            {!!csvReport?.data.length > 0 && (
              <CSVLink ref={clickRef} {...csvReport}></CSVLink>
            )}
          </Box>
        </Flex>
        <Box
          borderRadius={15}
          bgColor="gray.50"
          color="#000"
          // boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
          overflow={`scroll`}
          maxH="550px">
          {payoutlist?.docs?.length > 0 ? (
            <Table borderRadius={20} variant="striped">
              <Thead
                fontSize={{
                  "2xl": "13px",
                  xl: "13px",
                  md: "10px",
                  lg: "10px",
                  sm: "8px",
                }}
                h="40px"
                className="table__head"
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
              >
                <Tr
                  textAlign="center"
                  textTransform="capitalize"
                  h="30px"
                  borderBottomWidth={3}
                  color="#fff"
                  borderRadius="20px">
                  <Th color="#fff">Payout ID</Th>
                  <Th textAlign="center" color="#fff">
                    Account ID
                  </Th>
                  {/* <Th textAlign='center' color='#fff'>Address</Th> */}
                  <Th textAlign="center" color="#fff">
                    Confirmed Amount
                  </Th>
                  {/* <Th textAlign='center' color='#fff'>ID | Passport</Th> */}
                  <Th textAlign="center" color="#fff">
                    Country
                  </Th>
                  {/* <Th textAlign='center' color='#fff'>Created At</Th> */}
                  {/* <Th textAlign='center' color='#fff'>Deleted At</Th> */}
                  <Th textAlign="center" color="#fff">
                    Email
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Full name
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Payment ID
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Payment method
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Ticket
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Created At
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Updated At
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Validation
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Payment
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Communication
                  </Th>
                  {/* <Th textAlign='center' color='#fff'>client Response</Th> */}
                  <Th textAlign="center" color="#fff">
                    Actions
                  </Th>
                  <Th textAlign="center" color="#fff">
                    Details
                  </Th>
                </Tr>
              </Thead>
              <Tbody
                // bgGradient={`linear(to-l, gray.100, gray.300)`}
                color="black">
                {!isLoading ? (
                  payoutlist?.docs?.map((payoutlist, i) => (
                    <Tr
                      textAlign="center"
                      fontSize={{
                        "2xl": "13px",
                        xl: "13px",
                        md: "12px",
                        lg: "12px",
                        sm: "10px",
                      }}
                      key={i}
                      // borderBottom="1px"
                      // borderColor={colors.lightBlue}
                    >
                      <Td py="7px">{payoutlist?._id}</Td>
                      <Td py="7px">{payoutlist?.accountID}</Td>
                      {/* <Td py="7px">{payoutlist?.address}</Td> */}
                      <Td py="7px">{payoutlist?.requestedAmount}</Td>
                      {/* <Td py="7px">{payoutlist?.cin_passport}</Td> */}
                      <Td py="7px">{payoutlist?.country}</Td>
                      {/* <Td py="7px"> */}
                      {/* {payoutlist?.createdAt?.slice(0, 10)} */}
                      {/* </Td> */}
                      {/* <Td py="7px"> */}
                      {/* {payoutlist?.deletedAt?.slice(0, 10) ?? "N/A"} */}
                      {/* </Td> */}
                      <Td py="7px">{payoutlist?.email}</Td>
                      <Td py="7px">{payoutlist?.fullName}</Td>

                      {/* <Td py="7px">{payoutlist?.paymentId}</Td> */}
                      <Td py="7px">
                        <CustomToolTip
                          paymentId={payoutlist?.paymentId}
                          paymentMethod={payoutlist?.paymentMethod}
                        />
                        {/* {payoutlist?.paymentId} */}
                      </Td>
                      <Td py="7px">{payoutlist?.paymentMethod}</Td>
                      <Td py="7px">{payoutlist?.ticketNbr}</Td>
                      <Td py="7px">
                        {/* {moment(payoutlist?.createdAt).fromNow(true)} ago */}
                        {moment(payoutlist?.createdAt).format("DD/MM/YYYY")}
                      </Td>
                      <Td py="7px">
                        {/* {moment(payoutlist?.updatedAt).fromNow(true)} ago */}
                        {moment(payoutlist?.updatedAt).format("DD/MM/YYYY")}
                      </Td>

                      <Td py="7px">
                        <Menu>
                          <MenuButton
                            disabled={
                              payoutlist?.paymentStatus === "done"
                                ? true
                                : false
                            }
                            as={Button}
                            rightIcon={<BiChevronDown />}
                            borderRadius={50}
                            fontSize="12px"
                            bgColor={
                              payoutlist?.validationStatus === "pending"
                                ? "yellow.400"
                                : payoutlist?.validationStatus === "done"
                                ? "green.400"
                                : "red.400"
                            }
                            boxShadow="lg"
                            color="#ffff"
                            _hover={{
                              bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                            }}>
                            {payoutlist?.validationStatus}
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                onOpen();
                                setStatusUpdate({
                                  validationStatus: "pending",
                                  payout: payoutlist,
                                  reason: "",
                                });
                              }}>
                              Pending
                            </MenuItem>
                            {roles?.includes("PayoutMaster") ||
                              (roles?.includes("MASTER") && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      onOpen();
                                      setStatusUpdate({
                                        validationStatus: "declined",
                                        payout: payoutlist,
                                        reason: "",
                                      });
                                    }}>
                                    Declined
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      onOpen();
                                      setStatusUpdate({
                                        validationStatus: "done",
                                        payout: payoutlist,
                                        reason: "",
                                      });
                                    }}>
                                    Done
                                  </MenuItem>
                                </>
                              ))}
                          </MenuList>
                        </Menu>
                      </Td>
                      <Td py="7px">
                        <Menu>
                          <MenuButton
                            as={Button}
                            rightIcon={<BiChevronDown />}
                            borderRadius={50}
                            fontSize="12px"
                            maxW={"120px"}
                            whiteSpace={"pre-wrap"}
                            bgColor={
                              payoutlist?.paymentStatus === "WaitingForCustomer"
                                ? "orange.400"
                                : payoutlist?.paymentStatus === "pending"
                                ? "yellow.400"
                                : payoutlist?.paymentStatus === "done"
                                ? "green.400"
                                : "red.400"
                            }
                            boxShadow="lg"
                            color="#ffff"
                            _hover={{
                              bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                            }}>
                            {payoutlist?.paymentStatus === "WaitingForCustomer"
                              ? "Waiting For Customer"
                              : payoutlist?.paymentStatus}
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                onOpen();
                                setStatusUpdate({
                                  paymentStatus: "pending",
                                  payout: payoutlist,
                                  reason: "",
                                });
                              }}>
                              Pending
                            </MenuItem>
                            {roles?.includes("PayoutMaster") ||
                              (roles?.includes("MASTER") && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      onOpen();
                                      setStatusUpdate({
                                        paymentStatus: "WaitingForCustomer",
                                        payout: payoutlist,
                                        reason: "",
                                      });
                                    }}>
                                    Waiting For Customer
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      onOpen();
                                      setStatusUpdate({
                                        paymentStatus: "declined",
                                        payout: payoutlist,
                                        reason: "",
                                      });
                                    }}>
                                    Declined
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      onOpen();
                                      setStatusUpdate({
                                        paymentStatus: "rejected",
                                        payout: payoutlist,
                                        reason: "",
                                      });
                                    }}>
                                    Rejected
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      onOpen();
                                      setStatusUpdate({
                                        paymentStatus: "done",
                                        payout: payoutlist,
                                        reason: "",
                                      });
                                    }}>
                                    Done
                                  </MenuItem>
                                </>
                              ))}
                          </MenuList>
                        </Menu>
                      </Td>
                      <Td py="7px">
                        <Menu>
                          <MenuButton
                            disabled={
                              payoutlist?.paymentStatus === "done"
                                ? true
                                : false
                            }
                            as={Button}
                            rightIcon={<BiChevronDown />}
                            borderRadius={50}
                            fontSize="12px"
                            bgColor={
                              payoutlist?.communicationStatus === "pending"
                                ? "yellow.400"
                                : payoutlist?.communicationStatus === "done"
                                ? "green.400"
                                : "red.400"
                            }
                            boxShadow="lg"
                            color="#ffff"
                            _hover={{
                              bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                            }}>
                            {payoutlist?.communicationStatus}
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                onOpen();
                                setStatusUpdate({
                                  communicationStatus: "pending",
                                  payout: payoutlist,
                                  reason: "",
                                });
                              }}>
                              Pending
                            </MenuItem>
                            {roles?.includes("PayoutMaster") ||
                              (roles?.includes("MASTER") && (
                                <>
                                  <MenuItem
                                    onClick={() => {
                                      onOpen();
                                      setStatusUpdate({
                                        communicationStatus: "declined",
                                        payout: payoutlist,
                                        reason: "",
                                      });
                                    }}>
                                    Declined
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      onOpen();
                                      setStatusUpdate({
                                        communicationStatus: "done",
                                        payout: payoutlist,
                                        reason: "",
                                      });
                                    }}>
                                    Done
                                  </MenuItem>
                                </>
                              ))}
                          </MenuList>
                        </Menu>
                      </Td>
                      {/* <Td py="7px">
                      <Menu>
                        <MenuButton
                          disabled={
                            payoutlist?.paymentStatus === "done" ? true : false
                          }
                          as={Button}
                          rightIcon={<BiChevronDown />}
                          borderRadius={50}
                          fontSize="12px"
                          bgColor={
                            payoutlist?.clientResponseStatus === "pending"
                              ? "yellow.400"
                              : payoutlist?.clientResponseStatus === "done"
                              ? "green.400"
                              : "orange.400"
                          }
                          boxShadow="lg"
                          color="#ffff"
                          _hover={{
                            bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                          }}>
                          {payoutlist?.clientResponseStatus}
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              onOpen();
                              setStatusUpdate({
                                clientResponseStatus: "waiting",
                                payout: payoutlist,
                                reason: "",
                              });
                            }}>
                            Waiting
                          </MenuItem>
                          {roles?.includes("PayoutMaster") ||
                            (roles?.includes("MASTER") && (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    onOpen();
                                    setStatusUpdate({
                                      clientResponseStatus: "pending",
                                      payout: payoutlist,
                                      reason: "",
                                    });
                                  }}>
                                  Pending
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    onOpen();
                                    setStatusUpdate({
                                      clientResponseStatus: "done",
                                      payout: payoutlist,
                                      reason: "",
                                    });
                                  }}>
                                  Done
                                </MenuItem>
                              </>
                            ))}
                        </MenuList>
                      </Menu>
                    </Td> */}

                      <Td>
                        <Menu>
                          <MenuButton
                            disabled={
                              payoutlist?.paymentStatus === "done"
                                ? true
                                : false
                            }
                            as={Button}
                            borderRadius={50}
                            fontSize="12px"
                            className="app_btn"
                            boxShadow="lg"
                            color="#ffff">
                            <BiChevronDown />
                          </MenuButton>
                          <MenuList>
                            {payoutlist?.paymentStatus !== "done" &&
                              (roles?.includes("PayoutMaster") ||
                                roles?.includes("MASTER")) && (
                                <MenuItem
                                  onClick={() => {
                                    setDeletePayout(payoutlist);
                                    onOpen();
                                  }}>
                                  Delete
                                </MenuItem>
                              )}
                            {(roles?.includes("PayoutMaster") ||
                              roles?.includes("MASTER")) && (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    setEditPayout(payoutlist);
                                    setCryptoC(payoutlist.cryptoCurrency);
                                    setNetworkL(payoutlist.netowrk);
                                    onOpen();
                                  }}>
                                  Edit
                                </MenuItem>
                              </>
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                      <Td py="7px">
                        <Button
                          onClick={() => {
                            setDetailsPayout(payoutlist);
                            onOpen();
                          }}
                          borderRadius={50}
                          fontSize="12px"
                          boxShadow="lg"
                          color="#ffff"
                          className="app_btn">
                          <BiDetail />
                        </Button>
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <>
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="blue.500"
                      size="xl"
                    />
                  </>
                )}
              </Tbody>
            </Table>
          ) : (
            <Flex
              borderRadius={15}
              bgGradient={`linear(to-l, gray.100, gray.300)`}
              color={colors.ocean}
              boxShadow={`0px 5px 10px 5px rgba(0,183,196,0.35)`}
              p="20px"
              h="500px"
              justify={`center`}
              align="center"
              fontSize={30}>
              No data to display !!
            </Flex>
          )}
        </Box>

        <Box>
          <Flex justify="center" gap={10} mt={5}>
            <Box>
              <Button
                onClick={() => {
                  if (payoutlist?.hasPrevPage) {
                    setFilterBlacklist({
                      ...filterBlacklist,
                      page: payoutlist?.prevPage,
                    });
                  }
                }}
                disabled={!payoutlist?.hasPrevPage}
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                color="#ffff"
                className="app_btn"
                // _hover={{
                //   bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                // }}
              >
                Previous
              </Button>
            </Box>
            <Pagination1
              pagesQuantity={payoutlist?.totalPages}
              curPage={payoutlist?.page}
              setCurPage={(page) => {
                setFilterBlacklist({
                  ...filterBlacklist,
                  page: page,
                });
              }}
            />

            <Box>
              <Button
                onClick={() => {
                  if (payoutlist?.hasNextPage) {
                    setFilterBlacklist({
                      ...filterBlacklist,
                      page: payoutlist?.nextPage,
                    });
                  }
                }}
                className="app_btn"
                disabled={!payoutlist?.hasNextPage}
                // bgGradient={`linear(to-b, #46ced8, #008ab4)`}
                color="#ffff"
                // _hover={{
                //   bgGradient: `linear(to-r, #008ab4, #46ced8)`,
                // }}
              >
                Next
              </Button>
            </Box>
          </Flex>
        </Box>
      </Box>

      <>
        <Modal
          closeOnOverlayClick={false}
          scrollBehavior={"inside"}
          size={{
            sm: "md",
            lg: detailsPayot?._id ? "6xl" : editPayout?._id ? "4xl" : "md",
          }}
          isOpen={isOpen}
          onClose={() => {
            onClose();
            setStatusUpdate({
              status: "",
              payoutId: "",
              reason: "",
            });
            setDeletePayout({});
            setEditPayout({});
            setDetailsPayout({});
          }}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              {detailsPayot?._id && (
                <>Details of payout {detailsPayot?.fullName}</>
              )}

              {editPayout?._id && (
                <>Are you sure you want to edit this payout ?</>
              )}

              {deletePayout?._id && (
                <>Are you sure you want to delete this payout ?</>
              )}

              {statusUpdate?.status && (
                <>
                  Are you sure you want to change the status to{" "}
                  {statusUpdate?.status} ?
                </>
              )}

              {statusUpdate?.validationStatus && (
                <>
                  Are you sure you want to change the status to{" "}
                  {statusUpdate?.status} ?
                </>
              )}

              {statusUpdate?.communicationStatus && (
                <>
                  Are you sure you want to change the Communication Status to{" "}
                  {statusUpdate?.communicationStatus} ?
                </>
              )}
              {statusUpdate?.clientResponseStatus && (
                <>
                  Are you sure you want to change the Client Response Status to{" "}
                  {statusUpdate?.clientResponseStatus} ?
                </>
              )}
              {statusUpdate?.paymentStatus && (
                <>
                  Are you sure you want to change the Payment Status to{" "}
                  {statusUpdate?.paymentStatus} ?
                </>
              )}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              {detailsPayot?._id && (
                <>
                  <Grid
                    templateColumns={{
                      md: "repeat(1,1fr)",
                      lg: "repeat(2, 2fr)",
                    }}
                    gap={6}
                    divider={<StackDivider borderColor="gray.200" />}>
                    <GridItem className="sidebar_nav" borderWidth="2px" p={4}>
                      <Flex
                        flexDir={"column"}
                        justifyContent={"space-evenly"}
                        gap={5}
                        alignItems={"flex-start"}>
                        <Heading color={colors.blue} as="h4" size={"md"}>
                          {" "}
                          Personal Information
                        </Heading>
                        <Stack spacing={0}>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              user ID{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {` : ` + detailsPayot?.userID ?? "N/A"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              fontWeight="bold"
                              minW={"110px"}
                              mr={2}
                              as="span">
                              Full Name{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {` : ` + detailsPayot?.fullName ?? "N/A"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              fontWeight="bold"
                              minW={"110px"}
                              mr={2}
                              as="span">
                              Email{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {` : ` + detailsPayot?.email ?? "N/A"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              fontWeight="bold"
                              minW={"110px"}
                              mr={2}
                              as="span">
                              {" "}
                              ID | Passport{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" "}
                              {` : ` + detailsPayot?.cin_passport ?? "N/A"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              fontWeight="bold"
                              minW={"110px"}
                              mr={2}
                              as="span">
                              Country{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" "}
                              {` : ` + detailsPayot?.country ?? "N/A"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              fontWeight="bold"
                              minW={"110px"}
                              mr={2}
                              as="span">
                              Address{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" "}
                              {` : ` + detailsPayot?.address ?? "N/A"}{" "}
                            </Text>
                          </Box>
                        </Stack>
                      </Flex>
                    </GridItem>
                    <GridItem className="sidebar_nav" borderWidth="2px" p={4}>
                      <Flex
                        flexDir={"column"}
                        justifyContent={"space-evenly"}
                        gap={5}
                        alignItems={"flex-start"}>
                        <Heading color={colors.blue} as="h4" size={"md"}>
                          {" "}
                          Payment Information
                        </Heading>
                        <Stack spacing={0}>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"140px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Payment Method{" "}
                            </Text>
                            {" : " + detailsPayot.paymentMethod}
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"140px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              {" "}
                              {detailsPayot.paymentMethod === "Rise"
                                ? "Rise account"
                                : detailsPayot.paymentMethod === "deel"
                                ? "Deel account"
                                : detailsPayot.paymentMethod === "Bank Transfer"
                                ? "IBAN"
                                : "Wallet Address"}
                            </Text>
                            {" : " + detailsPayot.paymentId}
                          </Box>
                          {detailsPayot.paymentMethod === "Bank Transfer" && (
                            <>
                              <Box display="flex" alignItems="center">
                                <Text
                                  minW={"140px"}
                                  fontWeight="bold"
                                  mr={2}
                                  as="span">
                                  Swift Code
                                </Text>
                                {" : " + detailsPayot.swiftCode}
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Text
                                  minW={"140px"}
                                  fontWeight="bold"
                                  mr={2}
                                  as="span">
                                  Bank Address
                                </Text>
                                {" : " + detailsPayot.bankAddress}
                              </Box>
                            </>
                          )}
                          {detailsPayot.paymentMethod === "crypto" && (
                            <>
                              <Box display="flex" alignItems="center">
                                <Text
                                  minW={"140px"}
                                  fontWeight="bold"
                                  mr={2}
                                  as="span">
                                  Network
                                </Text>
                                {" : " + detailsPayot.network}
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Text
                                  minW={"140px"}
                                  fontWeight="bold"
                                  mr={2}
                                  as="span">
                                  Crypto Currency
                                </Text>
                                {" : " + detailsPayot.cryptoCurrency}
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Flex>
                    </GridItem>

                    {/* 
                  <b>Amount : </b>
                  {detailsPayot?.amount ?? "N/A"}
                  <br />
                  <br /> */}

                    {/* 
                  <b>Status : </b>
                  {detailsPayot?.status ?? "N/A"}
                  <br />
                  <br /> */}
                    <GridItem className="sidebar_nav" borderWidth="2px" p={4}>
                      <Flex
                        flexDir={"column"}
                        justifyContent={"space-evenly"}
                        gap={5}
                        alignItems={"flex-start"}>
                        <Heading color={colors.blue} as="h4" size={"md"}>
                          {" "}
                          Account Information
                        </Heading>
                        <Stack spacing={0}>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Account ID{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" "}
                              {" : " + detailsPayot?.accountID ?? "N/A"}{" "}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Account Type{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" : " + detailsPayot?.accountType ?? "N/A"}{" "}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Account Balance{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" "}
                              {" : " + detailsPayot?.accountBalance ??
                                "N/A"}{" "}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Account Creation Date{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" : " +
                                moment(
                                  detailsPayot?.AccountCreationDate
                                ).format("YYYY-MM-DD") ?? "N/A"}{" "}
                            </Text>
                          </Box>
                        </Stack>
                      </Flex>
                    </GridItem>
                    <GridItem className="sidebar_nav" borderWidth="2px" p={4}>
                      <Flex
                        flexDir={"column"}
                        justifyContent={"space-evenly"}
                        gap={5}
                        alignItems={"flex-start"}>
                        <Heading color={colors.blue} as="h4" size={"md"}>
                          {" "}
                          Action Information
                        </Heading>
                        <Stack spacing={0}>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Requested Amount
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" : " + detailsPayot?.requestedAmount ?? "N/A"}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Withdraw Amount{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" : " + detailsPayot?.withdrawAmount ?? "N/A"}{" "}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              {" "}
                              Active Trading Days{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" : " + detailsPayot?.nbrActiveTradingDays ??
                                "N/A"}{" "}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              withdraw Percentage Permitted
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" : " +
                                detailsPayot?.withdrawPercentagePermitted +
                                "%" ?? "N/A"}{" "}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Ticket number{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" : " + detailsPayot?.ticketNbr ?? "N/A"}
                            </Text>
                          </Box>
                        </Stack>
                      </Flex>
                    </GridItem>
                    <GridItem className="sidebar_nav" borderWidth="2px" p={4}>
                      <Flex
                        flexDir={"column"}
                        justifyContent={"space-evenly"}
                        gap={5}
                        alignItems={"flex-start"}>
                        <Heading color={colors.blue} as="h4" size={"md"}>
                          {" "}
                          Actions Status
                        </Heading>

                        <Stack spacing={0}>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              {" "}
                              Validation status :{" "}
                            </Text>
                            <Badge
                              color={checkColor(
                                detailsPayot?.validationStatus
                              )}>
                              {detailsPayot?.validationStatus}
                            </Badge>
                          </Box>

                          {detailsPayot?.validationStatus !== "done" &&
                            detailsPayot?.declineValidationReason && (
                              <>
                                <Box display="flex" alignItems="center">
                                  <Text
                                    minW={"110px"}
                                    fontWeight="bold"
                                    mr={2}
                                    as="span">
                                    {" "}
                                    Validation Decline Reason :{" "}
                                  </Text>
                                  <Text fontWeight="normal" mr={2} as="span">
                                    {detailsPayot?.declineValidationReason}
                                  </Text>
                                </Box>
                              </>
                            )}

                          {detailsPayot?.validationStatusUpdatedAt && (
                            <>
                              <Box display="flex" alignItems="center">
                                <Text
                                  minW={"110px"}
                                  fontWeight="bold"
                                  mr={2}
                                  as="span">
                                  {" "}
                                  Validation Status Updated At :{" "}
                                </Text>
                                <Text fontWeight="normal" mr={2} as="span">
                                  {detailsPayot?.validationStatusUpdatedAt
                                    .replace("T", " ")
                                    .replace("Z", "")}
                                </Text>
                              </Box>
                            </>
                          )}
                          {/* {detailsPayot?.validationStatus !== "done" &&
                            detailsPayot?.declineValidationReason} */}

                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Payment Status :{" "}
                            </Text>
                            <Badge
                              color={checkColor(detailsPayot?.paymentStatus)}>
                              {" "}
                              {detailsPayot?.paymentStatus ?? "N/A"}
                            </Badge>
                          </Box>
                          {detailsPayot?.paymentStatus !== "done" &&
                            detailsPayot?.reason && (
                              <>
                                <Box display="flex" alignItems="center">
                                  <Text
                                    minW={"110px"}
                                    fontWeight="bold"
                                    mr={2}
                                    as="span">
                                    {detailsPayot.paymentStatus === "declined"
                                      ? " Payment Decline Reason :"
                                      : detailsPayot?.paymentStatus ===
                                        "rejected"
                                      ? " Payment Rejection Reason"
                                      : "Payment Status Reason"}
                                  </Text>
                                  <Text fontWeight="normal" mr={2} as="span">
                                    {" "}
                                    {detailsPayot?.reason}
                                  </Text>
                                </Box>
                              </>
                            )}
                          {detailsPayot?.paymentStatusUpdatedAt && (
                            <>
                              <Box display="flex" alignItems="center">
                                <Text
                                  minW={"110px"}
                                  fontWeight="bold"
                                  mr={2}
                                  as="span">
                                  {" "}
                                  Validation Status Updated At :{" "}
                                </Text>
                                <Text fontWeight="normal" mr={2} as="span">
                                  {detailsPayot?.paymentStatusUpdatedAt
                                    .replace("T", " ")
                                    .replace("Z", "")}
                                </Text>
                              </Box>
                            </>
                          )}
                          {/* {detailsPayot?.paymentStatus !== "done" &&
                            detailsPayot?.reason} */}
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Communication Status :{" "}
                            </Text>
                            <Badge
                              color={checkColor(
                                detailsPayot?.communicationStatus
                              )}>
                              {" "}
                              {detailsPayot?.communicationStatus ?? "N/A"}{" "}
                            </Badge>
                          </Box>
                          {detailsPayot?.communicationStatusUpdatedAt && (
                            <>
                              <Box display="flex" alignItems="center">
                                <Text
                                  minW={"110px"}
                                  fontWeight="bold"
                                  mr={2}
                                  as="span">
                                  {" "}
                                  Communiacation Status Updated At :{" "}
                                </Text>
                                <Text fontWeight="normal" mr={2} as="span">
                                  {detailsPayot?.communicationStatusUpdatedAt
                                    .replace("T", " ")
                                    .replace("Z", "")}
                                </Text>
                              </Box>
                            </>
                          )}
                          {/* <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              client Response status :{" "}
                            </Text>
                            <Badge
                              color={checkColor(
                                detailsPayot?.clientResponseStatus
                              )}>
                              {detailsPayot?.clientResponseStatus ?? "N/A"}
                            </Badge>
                          </Box> */}
                        </Stack>
                      </Flex>
                    </GridItem>
                    <GridItem className="sidebar_nav" borderWidth="2px" p={4}>
                      <Flex
                        flexDir={"column"}
                        justifyContent={"space-evenly"}
                        gap={5}
                        alignItems={"flex-start"}>
                        <Heading color={colors.blue} as="h4" size={"md"}>
                          {" "}
                          Additional Informations
                        </Heading>
                        <Stack spacing={0}>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              {" "}
                              Created At
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" "}
                              {" : " +
                                moment(detailsPayot?.createdAt).format(
                                  "YYYY-MM-DD"
                                ) ?? "N/A"}
                            </Text>
                          </Box>

                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Updated At{" "}
                            </Text>
                            <Text fontWeight="normal" mr={2} as="span">
                              {" : " +
                                moment(detailsPayot?.updatedAt).format(
                                  "YYYY-MM-DD"
                                ) ?? "N/A"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Is Deleted{" "}
                            </Text>
                            {/* <br /> */}
                            <Text fontWeight="normal" mr={2} as="span">
                              {" "}
                              {" : " + detailsPayot?.isDeleted.toString() ??
                                "N/A"}
                            </Text>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Text
                              minW={"110px"}
                              fontWeight="bold"
                              mr={2}
                              as="span">
                              Deleted At{" "}
                            </Text>
                            {/* <br /> */}
                            <Text fontWeight="normal" mr={2} as="span">
                              {" "}
                              {" : " +
                                moment(detailsPayot?.deletedAt).format(
                                  "YYYY-MM-DD"
                                ) ?? "N/A"}
                            </Text>
                          </Box>
                        </Stack>
                      </Flex>
                    </GridItem>
                  </Grid>
                </>
              )}

              {editPayout?._id && (
                <>
                  By clicking on save, you will edit the payout
                  <br />
                  <br />
                  <Flex
                    flexDir={"column"}
                    justifyContent={"space-evenly"}
                    gap={6}>
                    <Box>
                      <Heading color={colors.blue} as="h4" size={"md"}>
                        {" "}
                        Personal Information
                      </Heading>
                      <Flex
                        mt={4}
                        flexDir={"row"}
                        justifyContent={"space-evenly"}
                        gap={5}>
                        <FormControl w={"35%"}>
                          <FormLabel>Full name</FormLabel>
                          <Input
                            value={editPayout?.fullName}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                fullName: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <FormControl w={"35%"}>
                          <FormLabel>Email</FormLabel>
                          <Input
                            value={editPayout?.email}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                email: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <FormControl w={"35%"}>
                          <FormLabel>ID | Passport </FormLabel>
                          <Input
                            placeholder="Select a country"
                            value={editPayout?.cin_passport}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                cin_passport: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        mt={4}
                        flexDir={"row"}
                        justifyContent={"space-evenly"}
                        gap={5}>
                        <FormControl w={"50%"}>
                          <FormLabel>Country</FormLabel>
                          <Select
                            placeholder="Select a country"
                            value={editPayout?.country}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                country: e.target.value,
                              })
                            }>
                            {countriesList.map((country, i) => (
                              <option key={i} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl w={"50%"}>
                          <FormLabel>Address</FormLabel>
                          <Input
                            value={editPayout?.address}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                address: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Flex>
                    </Box>
                    <Box>
                      <Heading color={colors.blue} as="h4" size={"md"}>
                        {" "}
                        Payment Informations
                      </Heading>
                      <Flex
                        flexDir={"row"}
                        justifyContent={"space-evenly"}
                        gap={5}
                        mt={4}>
                        <FormControl>
                          <FormLabel>Payment Methode</FormLabel>
                          <Select
                            placeholder="Select a Methode"
                            value={editPayout?.paymentMethod}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                paymentMethod: e.target.value,
                              })
                            }>
                            {/* <option key={"deel"} value={"deel"}>
                              Deel
                            </option> */}
                            <option key={"Rise"} value={"Rise"}>
                              Rise
                            </option>
                            <option
                              key={"Bank Transfer"}
                              value={"Bank Transfer"}>
                              Bank Transfer
                            </option>
                            <option key={"Crypto"} value={"crypto"}>
                              Crypto
                            </option>
                          </Select>
                        </FormControl>
                        <FormControl>
                          <FormLabel>
                            {" "}
                            {editPayout?.paymentMethod === "Rise"
                              ? "Rise account"
                              : editPayout?.paymentMethod === "deel"
                              ? "Deel account"
                              : editPayout?.paymentMethod === "Bank Transfer"
                              ? "IBAN"
                              : "Wallet Address"}{" "}
                          </FormLabel>
                          <Input
                            value={editPayout?.paymentId}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                paymentId: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        {editPayout?.paymentMethod === "crypto" && (
                          <>
                            <FormControl>
                              <FormLabel>Network</FormLabel>
                              <Select
                                onChange={(e) => {
                                  setEditPayout({
                                    ...editPayout,
                                    network: e.target.value,
                                  });
                                  setNetworkL(e.target.value);
                                }}
                                defaultValue={
                                  editPayout?.network !== "ERC-20" &&
                                  editPayout.network !== "BEP-20" &&
                                  editPayout.network !== "TRC-20"
                                    ? "Other"
                                    : editPayout?.network
                                }
                                // onChange={(e) => setPaymentMethod(e.target.value)}
                              >
                                <option value="ERC-20">ERC-20</option>
                                <option value="BEP-20">BEP-20</option>
                                <option value="TRC-20">TRC-20</option>
                                <option value="Other">Other</option>
                              </Select>

                              {networkL !== "ERC-20" &&
                                networkL !== "BEP-20" &&
                                networkL !== "TRC-20" && (
                                  <>
                                    <Input
                                      mt={2}
                                      type="text"
                                      defaultValue={editPayout?.network}
                                      onChange={(e) => {
                                        setEditPayout({
                                          ...editPayout,
                                          network: e.target.value,
                                        });
                                      }}
                                    />
                                  </>
                                )}
                            </FormControl>
                            <FormControl w="100%">
                              <FormLabel>Crypto Currency</FormLabel>

                              <Select
                                defaultValue={
                                  crypto.includes(editPayout?.cryptoCurrency)
                                    ? editPayout?.cryptoCurrency
                                    : "Other"
                                }
                                onChange={(e) => {
                                  setCryptoC(e.target.value);
                                  setEditPayout({
                                    ...editPayout,
                                    cryptoCurrency: e.target.value,
                                  });
                                }}>
                                <option value="BNB">BNB</option>
                                <option value="USDT">USDT</option>
                                <option value="BUSD">BUSD</option>
                                <option value="BTC">BTC</option>
                                <option value="ETH">ETH</option>
                                <option value="XVG">XVG</option>
                                <option value="USDC">USDC</option>
                                <option value="TUSD">TUSD</option>
                                <option value="Other">Other</option>
                              </Select>
                              {!crypto.includes(cryptC) && (
                                <>
                                  <Input
                                    mt={2}
                                    value={editPayout?.cryptoCurrency}
                                    onChange={(e) =>
                                      setEditPayout({
                                        ...editPayout,
                                        cryptoCurrency: e.target.value,
                                      })
                                    }
                                  />
                                </>
                              )}
                            </FormControl>
                          </>
                        )}
                        {editPayout?.paymentMethod === "Bank Transfer" && (
                          <>
                            <FormControl>
                              <FormLabel>swiftCode</FormLabel>
                              <Input
                                value={editPayout?.swiftCode}
                                onChange={(e) =>
                                  setEditPayout({
                                    ...editPayout,
                                    swiftCode: e.target.value,
                                  })
                                }
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel> Bank Address </FormLabel>
                              <Input
                                name="bankAddress"
                                type="text"
                                value={editPayout?.bankAddress}
                                onChange={(e) => {
                                  setEditPayout({
                                    ...editPayout,
                                    bankAddress: e.target.value,
                                  });
                                }}
                              />
                            </FormControl>
                          </>
                        )}
                      </Flex>
                    </Box>
                    <Box>
                      <Heading color={colors.blue} as="h4" size={"md"}>
                        {" "}
                        Account Informations
                      </Heading>
                      <Flex
                        mt={4}
                        flexDir={"row"}
                        justifyContent={"space-evenly"}
                        gap={5}>
                        <FormControl>
                          <FormLabel>Account ID</FormLabel>
                          <Input
                            value={editPayout?.accountID}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                accountID: e.target.value,
                              })
                            }
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel>Account Creation Date</FormLabel>
                          <Input
                            type={"date"}
                            value={editPayout?.AccountCreationDate}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                AccountCreationDate: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <FormControl>
                          <FormLabel>Ticket Number</FormLabel>
                          <Input
                            value={editPayout?.ticketNbr}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                ticketNbr: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        flexDir={"row"}
                        justifyContent={"space-evenly"}
                        gap={5}>
                        <FormControl mt={4}>
                          <FormLabel>Withdraw Amount</FormLabel>
                          <Input
                            value={editPayout?.withdrawAmount}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                withdrawAmount: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Requested Amount</FormLabel>
                          <Input
                            value={editPayout?.requestedAmount}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                requestedAmount: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                        <FormControl mt={4}>
                          <FormLabel>Permited Withdraw Percentage</FormLabel>
                          <Select
                            placeholder="Select a Methode"
                            value={editPayout?.withdrawPercentagePermitted}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                withdrawPercentagePermitted: e.target.value,
                              })
                            }>
                            <option key={"25"} value={"25"}>
                              25%
                            </option>
                            <option key={"50"} value={"50"}>
                              50%
                            </option>
                            <option key={"90"} value={"90"}>
                              90%
                            </option>
                            <option key={"100"} value={"100"}>
                              100%
                            </option>
                          </Select>
                        </FormControl>
                      </Flex>
                    </Box>
                    <Box>
                      <Flex
                        flexDir={"row"}
                        justifyContent={"space-evenly"}
                        gap={5}>
                        <FormControl mt={4}>
                          <FormLabel>Active Trading Days</FormLabel>
                          <Input
                            value={editPayout?.nbrActiveTradingDays}
                            onChange={(e) =>
                              setEditPayout({
                                ...editPayout,
                                nbrActiveTradingDays: e.target.value,
                              })
                            }
                          />
                        </FormControl>
                      </Flex>
                    </Box>
                  </Flex>
                </>
              )}

              {deletePayout?._id && (
                <>
                  By clicking on save, you will delete the payout
                  <br />
                  <br />
                  Account ID: {deletePayout?.accountID}
                  <br /> Payment ID: {deletePayout?.paymentId}
                  <br /> Requested Amount: {deletePayout?.requestedAmount}
                  <br /> Payment Method: {deletePayout?.paymentMethod}
                  <br /> Ticket Number: {deletePayout?.ticketNbr}
                  <br /> Full Name: {deletePayout?.fullName}
                  <br /> Email: {deletePayout?.email}
                  <br /> Address: {deletePayout?.address}
                  <br /> Country: {deletePayout?.country}
                  <br /> ID/Passport: {deletePayout?.cin_passport}
                  <br /> Created At: {deletePayout?.createdAt}
                </>
              )}

              {(statusUpdate?.validationStatus ||
                statusUpdate?.communicationStatus ||
                statusUpdate?.paymentStatus ||
                statusUpdate.clientResponseStatus) && (
                <>
                  By clicking on save, you will change the status of the payout
                  to{" "}
                  <b>
                    {statusUpdate?.validationStatus ||
                      statusUpdate?.communicationStatus ||
                      statusUpdate?.paymentStatus}
                  </b>
                  <br />
                  <br />
                  Account ID: {statusUpdate?.payout?.accountID}
                  <br /> Payment ID: {statusUpdate?.payout?.paymentId}
                  <br /> Requested Amount:{" "}
                  {statusUpdate?.payout?.requestedAmount}
                  <br /> Payment Method: {statusUpdate?.payout?.paymentMethod}
                  <br /> Ticket Number: {statusUpdate?.payout?.ticketNbr}
                  <br /> Full Name: {statusUpdate?.payout?.fullName}
                  <br /> Email: {statusUpdate?.payout?.email}
                  <br /> Address: {statusUpdate?.payout?.address}
                  <br /> Country: {statusUpdate?.payout?.country}
                  <br /> ID/Passport: {statusUpdate?.payout?.cin_passport}
                  <br /> Created At: {statusUpdate?.payout?.createdAt}
                  <br />
                  <br />
                  {((statusUpdate?.paymentStatus &&
                    statusUpdate?.paymentStatus !== "done") ||
                    (statusUpdate?.validationStatus &&
                      statusUpdate?.validationStatus !== "done")) && (
                    <>
                      <b>Reason</b>
                      <Input
                        placeholder="Reason"
                        value={statusUpdate?.reason}
                        onChange={(e) =>
                          setStatusUpdate({
                            ...statusUpdate,
                            reason: e.target.value,
                          })
                        }
                      />
                    </>
                  )}
                </>
              )}
            </ModalBody>

            {!detailsPayot?._id && (
              <ModalFooter>
                <Button
                  colorScheme={deletePayout?._id ? "red" : "blue"}
                  mr={3}
                  onClick={async () => {
                    if (editPayout?._id) {
                      const {
                        _id,
                        cin_passport,
                        updatedAt,
                        createdAt,
                        isDeleted,
                        deletedAt,
                        userID,
                        accountBalance,
                        accountType,
                        __v,
                        clientResponseStatus,
                        blacklist,
                        ...editPayoutObj
                      } = editPayout;

                      await client
                        .put(
                          `${process.env.REACT_APP_API_HOST}/payout/${editPayout?._id}`,
                          editPayoutObj
                          // {
                          //   headers: {
                          //     "auth-token": auth?.accessToken,
                          //   },
                          // }
                        )
                        .then((res) => {
                          setEditPayout({});
                          onClose();

                          toast({
                            title: "Payout updated successfully",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                          refetchPayoutList();
                        })
                        .catch((err) => {
                          toast({
                            title:
                              err?.response?.data?.message ??
                              "Error updating payout",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        });
                    } else if (deletePayout?._id) {
                      await client
                        .delete(
                          `${process.env.REACT_APP_API_HOST}/payout/${deletePayout?._id}`
                          // {
                          //   headers: {
                          //     "auth-token": auth?.accessToken,
                          //   },
                          // }
                        )
                        .then((res) => {
                          setDeletePayout({});
                          onClose();

                          toast({
                            title: "Payout deleted successfully",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                          refetchPayoutList();
                        })
                        .catch((err) => {
                          toast({
                            title:
                              err?.response?.data?.message ??
                              "Error deleting payout",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        });
                    } else {
                      let obj = {
                        [statusUpdate?.validationStatus
                          ? "validationStatus"
                          : statusUpdate?.paymentStatus
                          ? "paymentStatus"
                          : statusUpdate.communicationStatus
                          ? "communicationStatus"
                          : "clientResponseStatus"]:
                          statusUpdate?.paymentStatus ??
                          statusUpdate?.validationStatus ??
                          statusUpdate?.communicationStatus ??
                          statusUpdate?.clientResponseStatus,
                      };

                      if (
                        statusUpdate?.paymentStatus &&
                        statusUpdate.paymentStatus !== "done"
                      ) {
                        obj.reason = statusUpdate?.reason;
                      }
                      if (
                        statusUpdate?.validationStatus &&
                        statusUpdate.validationStatus !== "done"
                      ) {
                        obj.declineValidationReason = statusUpdate?.reason;
                      }
                      const { userID, ...data } = obj;
                      console.log("data", data);
                      await client
                        .put(
                          `${process.env.REACT_APP_API_HOST}/payout/${statusUpdate?.payout?._id}`,
                          data
                          // {
                          //   headers: {
                          //     "auth-token": auth?.accessToken,
                          //   },
                          // }
                        )
                        .then((res) => {
                          setDeletePayout({});
                          onClose();
                          setStatusUpdate({
                            status: "",
                            payoutId: "",
                            reason: "",
                          });
                          toast({
                            title: "Status updated successfully",
                            status: "success",
                            duration: 9000,
                            isClosable: true,
                          });
                          refetchPayoutList();
                        })
                        .catch((err) => {
                          toast({
                            title:
                              err?.response?.data?.message + " dqdqw" ??
                              "Error updating status",
                            status: "error",
                            duration: 9000,
                            isClosable: true,
                          });
                        });
                    }
                  }}>
                  {deletePayout?._id ? "Delete" : "Save"}
                </Button>
                <Button
                  onClick={() => {
                    onClose();
                    setStatusUpdate({
                      status: "",
                      payoutId: "",
                      reason: "",
                    });
                    setDeletePayout({});
                    setEditPayout({});
                  }}>
                  Cancel
                </Button>
              </ModalFooter>
            )}
          </ModalContent>
        </Modal>
      </>
    </div>
  );
}

export default ListPayout;
