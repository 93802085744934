import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  Select,
  Button,
  Heading,
  Badge,
  Collapse,
  useDisclosure,
  useToast,
  Grid,
  GridItem,
  Text,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import Pagination1 from "../../utils/pagination"; // Assuming you have a pagination component
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import axios from "axios";
const AccountErrorList = () => {
  const [userId, setUserId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");
  const [provider, setProvider] = useState("");
  const [status, setStatus] = useState("");
  //   const [accountErrors, setAccountErrors] = useState([
  //     {
  //       provider: "s2f",
  //       body: {
  //         accountName: "test",
  //         accountType: "testType",
  //         userId: 111,
  //       },
  //       response: "AAA",
  //       user_id: 1,
  //       invoice_id: 1,
  //       status: "pending",
  //       isDeleted: false,
  //       deletedAt: null,
  //       createdAt: {
  //         $date: {
  //           $numberLong: "1738767406733",
  //         },
  //       },
  //       updatedAt: {
  //         $date: {
  //           $numberLong: "1738767406733",
  //         },
  //       },
  //       __v: 0,
  //     },
  //     {
  //       provider: "s2f",
  //       body: {
  //         accountName: "test",
  //         accountType: "testType",
  //         userId: 111,
  //       },
  //       response: "AAA",
  //       user_id: 1,
  //       invoice_id: 1,
  //       status: "rejected",
  //       isDeleted: false,
  //       deletedAt: null,
  //       createdAt: {
  //         $date: {
  //           $numberLong: "1738767406733",
  //         },
  //       },
  //       updatedAt: {
  //         $date: {
  //           $numberLong: "1738767406733",
  //         },
  //       },
  //       __v: 0,
  //     },
  //     {
  //       provider: "s2f",
  //       body: {
  //         accountName: "test",
  //         accountType: "testType",
  //         userId: 111,
  //       },
  //       response: "AAA",
  //       user_id: 1,
  //       invoice_id: 1,
  //       status: "solved",
  //       isDeleted: false,
  //       deletedAt: null,
  //       createdAt: {
  //         $date: {
  //           $numberLong: "1738767406733",
  //         },
  //       },
  //       updatedAt: {
  //         $date: {
  //           $numberLong: "1738767406733",
  //         },
  //       },
  //       __v: 0,
  //     },
  //   ]);
  const [accountErrors, setAccountErrors] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const toast = useToast();
  const [fetchTrigger, setFetchTrigger] = useState(0); // State to trigger refetch

  useEffect(() => {
    // Fetch account errors from the endpoint
    const fetchAccountErrors = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/account-creation?limit=10&page=${page}&userId=${userId}&invoiceId=${invoiceId}&provider=${provider}&status=${status}`
        );
        const data = response.data;
        console.log(data);
        setAccountErrors(data.docs);
        setTotalPages(data.totalPages);
      } catch (error) {
        toast({
          title: "Error fetching account errors",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchAccountErrors();
  }, [page, userId, invoiceId, provider, status, fetchTrigger]);

  return (
    <Box p={4}>
      <Heading mb={4}>Account Errors</Heading>
      <Grid
        templateColumns="repeat(4, 300px)"
        justifyContent={"center"}
        alignItems={"center"}
        gap={4}
        px={16}
        mb={4}>
        <GridItem>
          <FormControl>
            <FormLabel>User ID</FormLabel>
            <Input
              placeholder="User ID"
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Invoice ID</FormLabel>
            <Input
              placeholder="Invoice ID"
              value={invoiceId}
              onChange={(e) => setInvoiceId(e.target.value)}
            />
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Provider</FormLabel>
            <Select
              placeholder="Select Provider"
              value={provider}
              onChange={(e) => setProvider(e.target.value)}>
              <option value="s2f">ProjectX</option>
              <option value="tradovate">Tradovate</option>
              <option value="rithmic">Rithmic</option>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem>
          <FormControl>
            <FormLabel>Status</FormLabel>
            <Select
              placeholder="Select Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}>
              <option value="pending">Pending</option>
              <option value="error">Error</option>
              <option value="rejected">Rejected</option>
              <option value="solved">Solved</option>
            </Select>
          </FormControl>
        </GridItem>
      </Grid>
      <Box>
        {accountErrors.map((error, index) => (
          <ExpandableCard
            key={index}
            error={error}
            fetchTrigger={fetchTrigger}
            setFetchTrigger={setFetchTrigger}
          />
        ))}
      </Box>
      {accountErrors.length === 0 && (
        <Box
          mt={10}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h={"screen"}>
          <Text
            className="text-center text-gray-500 font-bold text-6xl"
            fontSize={"2xl"}
            fontWeight={"bold"}>
            No account errors foundd
          </Text>
        </Box>
      )}
      <Pagination1
        pagesQuantity={totalPages}
        curPage={page}
        setCurPage={setPage}
      />
    </Box>
  );
};

const ExpandableCard = ({ error, fetchTrigger, setFetchTrigger }) => {
  const { isOpen, onToggle } = useDisclosure();
  const [formData, setFormData] = useState(error.body);
  const toast = useToast();

  const handleInputChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_HOST}/fix-error`,
        {
          ...formData,
          invoice_id: error.invoice_id,
          user_id: error.user_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            // apikey: process.env.REACT_APP_API_KEY,
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "Error fixed successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setFetchTrigger((prev) => prev + 1); // Trigger refetch
      } else {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast({
        title: "Error",
        description: "Failed to fix the error.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={4} mb={4} borderWidth={1} borderRadius="md" boxShadow="md">
      <Flex justify="space-between" align="center">
        <Heading size="md">{error.user_id}</Heading>
        <Flex gap={2}>
          <Badge
            p={2}
            className="flex justify-center items-center"
            colorScheme={
              error.status === "pending"
                ? "yellow"
                : error.status === "rejected"
                ? "red"
                : "green"
            }
            rounded={"xl"}>
            {error.status}
          </Badge>
          <Box onClick={onToggle} cursor="pointer">
            {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          </Box>
        </Flex>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <Box mt={4}>
          <p>{error.response}</p>
          <form onSubmit={handleSubmit}>
            <Grid templateColumns="repeat(3, 1fr)" gap={4}>
              {Object.entries(formData).map(([key, value]) => (
                <GridItem key={key}>
                  <FormControl>
                    <FormLabel>{key}</FormLabel>
                    <Input
                      placeholder={key}
                      value={value}
                      onChange={(e) => handleInputChange(key, e.target.value)}
                      borderColor="#0177D9"
                      color="#0177D9"
                      _focus={{ borderColor: "#0177D9" }}
                      bgColor="gray.50"
                      borderRadius={8}
                      boxShadow="lg"
                    />
                  </FormControl>
                </GridItem>
              ))}
            </Grid>
            <Box mt={4} display="flex" justifyContent="center">
              <Button type="submit" colorScheme="blue">
                Update
              </Button>
            </Box>
          </form>
        </Box>
      </Collapse>
    </Box>
  );
};

export default AccountErrorList;
