import { Grid, GridItem } from "@chakra-ui/react";
import NavbarAffiliateSignup from "./NavbarAffiliateSignup"; // Create this component if needed
import { Outlet } from "react-router-dom";

const AffiliateSignupTemplate = (props) => {
  return (
    <Grid
      h="100vh"
      templateRows="repeat(15, 1fr)"
      templateColumns="repeat(1, 1fr)"
      gap={2}
      position="relative">
      <GridItem rowSpan={2}>
        <NavbarAffiliateSignup />
      </GridItem>
      <GridItem
        rowSpan={13}
        overflow="auto"
        borderRightRadius="20px"
        h="calc(100vh - 70px)">
        <Outlet />
      </GridItem>
    </Grid>
  );
};

export default AffiliateSignupTemplate;
