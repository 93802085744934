import React, { useState } from "react";
import {
  Box,
  Flex,
  Heading,
  Input,
  Button,
  Select,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router";
import { BiToggleLeft, BiToggleRight } from "react-icons/bi";
import logo from "../../assets/3d.png";
import logo1 from "../../assets/blackType.png";

export default function EnableDisableKYCContract() {
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation().pathname;

  const handleSubmit = async () => {
    if (!email || !type) {
      toast({
        title: "Validation Error",
        description: "Both fields are required.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/contracts/deactivate`,
        { email, type }
      );
      toast({
        title: "Submission Successful",
        description: "KYC status has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setEmail("");
      setType("");
    } catch (error) {
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "Failed to update KYC status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex flexDir="column" justify="space-evenly">
      <Box
        px="100px"
        py="40px"
        h="calc(100vh - 200px)"
        color="gray.50"
        overflow="auto">
        <Heading size="lg" color="black" mb={4}>
          Enable/Disable KYC
        </Heading>
        <Flex
          align="center"
          justify="center"
          flexDir="column"
          bg="gray.100"
          p={10}
          borderRadius="md"
          boxShadow="md">
          <Box mb={4} w="100%">
            <Input
              type="email"
              placeholder="Enter your email"
              borderColor="#0177D9"
              color="#0177D9"
              _focus={{ borderColor: "#0177D9" }}
              bgColor="gray.50"
              borderRadius={8}
              boxShadow="lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box mb={4} w="100%">
            <Select
              placeholder="Select KYC status"
              borderColor="#0177D9"
              color="#0177D9"
              _focus={{ borderColor: "#0177D9" }}
              bgColor="gray.50"
              borderRadius={8}
              boxShadow="lg"
              value={type}
              onChange={(e) => setType(e.target.value)}>
              <option value="KYC_ON">KYC_ON</option>
              <option value="KYC_OFF">KYC_OFF</option>
            </Select>
          </Box>
          <Button
            onClick={handleSubmit}
            isDisabled={loading}
            borderRadius={50}
            fontSize="14px"
            boxShadow="lg"
            color="#fff"
            px={8}
            py={6}
            bgGradient="linear(to-r, #008ab4, #46ced8)"
            _hover={{ bgGradient: "linear(to-r, #46ced8, #008ab4)" }}>
            {loading ? <Spinner size="sm" /> : "Submit"}
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
}
